/*
 * @Description: 
 * @Version: 1.0
 * @Author: caoyuheng
 * @Date: 2022-11-28 20:44:08
 * @LastEditors: caoyuheng
 * @LastEditTime: 2023-08-08 16:28:49
 * @FilePath: /doctorplat_qz_wx/src/utils/request.js
 * Copyright (C) 2023 caoyuheng. All rights reserved.
 */
// 在http.js中引入axios
import axios from 'axios'; // 引入axios
import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// vant的toast提示框组件，大家可根据自己的ui组件更改。
import {
    Toast,
} from 'vant';
// 环境的切换

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'https://www.admin.quanzhi360.com:446/';
} else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'https://www.admin.quanzhi360.com:446/';
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://www.admin.quanzhi360.com:446/';
}
axios.defaults.timeout = 20000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
// import store from '@/store/index';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // const token = store.state.token;        
        // token && (config.headers.Authorization = token);        
        return config;
    },
    error => {
        return Promise.error(error);
    })
// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        // console.log(response.data,'response')
        if(!response.data.status){
            Toast(response.data.message)
        }
        else  if (response.data.status) {
            return Promise.resolve(response);
        } else if (!response.data.status && response.data.statusCode == '400') {
            return Promise.resolve(response);
        } else {
            if (response.data.statusCode == '401') {
                localStorage.clear()
                var pageUrl = window.location.href
                    .replace(/[/]/g, '%2f')
                    .replace(/[:]/g, '%3a')
                    .replace(/[#]/g, '%23')
                    .replace(/[&]/g, '%26')
                    .replace(/[=]/g, '%3d')
                console.log(pageUrl, 'pageUrl111')
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc71603ead73103d2&redirect_uri=${pageUrl}&response_type=code&scope=snsapi_base&state=STATE&agentid=1000012#wechat_redirect`
                // window.onload()
            }
            // return Promise.reject(response);
            if (response.data.message) {
                Toast(response.data.message)
            } else {
                Toast('网络异常,请刷新重试')
            }
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        Toast('网络异常,请刷新重试')
    }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
          });
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
            Toast.clear();  
        }).catch(err => {
            reject(err.data)
            Toast.clear();  
        })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
          }); 
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
                Toast.clear();  
            })
            .catch(err => {
                reject(err.data)
                Toast.clear();  
            })
    });
}