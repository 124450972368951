/*
 * @Description: 
 * @Version: 1.0
 * @Author: caoyuheng
 * @Date: 2022-12-01 14:16:03
 * @LastEditors: caoyuheng
 * @LastEditTime: 2023-05-15 21:09:40
 * @FilePath: /doctor_plat_wx/src/api/user.js
 * Copyright (C) 2023 caoyuheng. All rights reserved.
 */

import {
    get,
    post
} from '@/utils/request'
export const userInfo = data => get('/api/ApiUser/GetUserInfo', data);
export const userInfoList = data => get('/api/ApiUser/GetRoles', data);
export const consultingCount = data => get('/api/ApiQuestion/QuestionRecord', data) //患者咨询记录
export const consultingDoctor = data => get('/api/apiexpert/QuestionList', data) //咨询师咨询记录
export const delRecords = data => get('/api/ApiQuestion/DeleteQuestion', data) //删除咨询记录
export const statistical = data => get('/api/ApiUser/QuestionChart', data) //咨询量统计
export const issueRecord = data => get('/api/ApiUser/PayChart', data) //打赏统计
export const userComment = data => get('/api/apiuser/CommentList', data) //用户评价
export const quickInformation = data => get('/api/apiExpert/GetQuickReplyList', data) //快捷信息 个人信息
export const quickSort = data => get('/api/apiExpert/QuickInfoOrder', data) //快捷信息排序
export const delQuick = data => get('/api/apiExpert/DeleteQuickReply', data) //删除快捷回复和个人资料
export const informationDef = data => get('/api/ApiQuestion/QuestionDetail', data) //咨询问题详情
export const zwQuestion = data => post('/api/ApiQuestion/SaveQuestionRepeat', data) //追加提问
export const endQuertion = data => get('/api/ApiQuestion/EndQuestion', data) //结束咨询
export const saveScore = data => post('/api/ApiQuestion/SaveScore', data) //咨询评价
export const resendCommon = data => post('/api/ApiExpert/SaveReply', data) //咨询回复
export const consultingCountDoctor = data => get('/api/ApiQuestion/ExpertQuestionRecord', data) //咨询师查看患者咨询记录
export const userEditCount = data => post('/api/apiUser/UserInfoHistory', data) //患者信息修改记录
export const addLabel = data => post('/api/apiExpert/SavePatientTag', data) //保存患者标签
export const delTags = data => get('/api/apiExpert/DeletePatientTag', data) //删除标签
export const delLine = data => get('/api/apiuser/ClearReadStatus', data) //消除用户中心红点
export const updateUser = data => post('/api/ApiUser/UpdateUserInfo', data) //完善信息
export const userId = data => get('/Auth/GetOpenId', data)//获取openid
export const behavior = data =>post('/api/ApiQuestion/ViewBehavior',data)//推荐案列行为记录