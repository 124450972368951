import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    // component: HomeView
    redirect:"index.html",
    meta:{
      showTab:true
    }
  },
  {
    path: '/auth/get',
    name: '首页',
    // component: HomeView
    redirect:"index.html",
    meta:{
      showTab:true
    }
  },
  {
    path: '/index.html',
    name: '首页',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue'),
    meta:{
      showTab:true,
      title:'首页',
      login:true
    }
  },
  {
    path: '/share.html',
    name: '咨询分享',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/share/index.vue'),
    meta:{
      showTab:true,
      title:'咨询分享',
    }
  },
  {
    path: '/user.html',
    name: '个人中心',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue'),
    meta:{
      showTab:true,
      title:'用户中心',
      login:true
    }
  },
  {
    path:'/wechat/advistoryList.html',
    name:'咨询记录',
    component: () => import(/* webpackChunkName: "about" */ '../views/consulting/index.vue'),
    meta:{
      login:true,
      title:'咨询记录'
    }
  },
  {
    path:'/advisoryRecordUser.html',
    name:'咨询记录',
    component: () => import(/* webpackChunkName: "about" */ '../views/consultingUser/index.vue'),
    meta:{
      login:true,
      title:'咨询记录'
    }
  },
  {
    path:'/doctorDef.html',
    name:'咨询师详情',
    component: () => import(/* webpackChunkName: "about" */ '../views/doctorDef/index.vue'),
    meta:{
      login:true,
      title:'咨询师'
    }
  },
  {
    path:'/question.html',
    name:'问题咨询',
    component: () => import(/* webpackChunkName: "about" */ '../views/questionAsk/index.vue'),
    meta:{
      login:true,
      title:'咨询问题填写'
    }
  },
  {
    path:'/statisticalRecord.html',
    name:'咨询统计',
    component:()=>import ('../../src/views/statisticalRecord/index.vue'),
    meta:{
      login:true,
      title:'咨询统计'
    }
  },
  {
    path:'/exceptionalRecord.html',
    name:'打赏统计',
    component:()=>import ('../../src/views/exceptionalRecord/index.vue'),
    meta:{
      login:true,
      title:'打赏记录'
    }
  },
  {
    path:'/userComment.html',
    name:'用户评价',
    component:()=>import ('../../src/views/userComment/index.vue'),
    meta:{
      login:true,
      title:'用户评价'
    }
  },
  {
    path:'/quickInformation.html',
    name:'快捷信息',
    component:()=>import ('../../src/views/quickInformation/index.vue'),
    meta:{
      login:true,
      title:'快捷信息'
    }
  },
  {
    path:'/information.html',
    name:'咨询详情',
    component:()=>import ('../../src/views/information/index.vue'),
    meta:{
      login:true,
      title:'咨询详情'
    }
  },
  {
    path:'/informationDoctor.html',
    name:'咨询师咨询详情',
    component:()=>import ('../../src/views/informationDoctor/index.vue'),
    meta:{
      login:true,
      title:'咨询详情'
    }
  },
  {
    path:'/userInformation.html',
    name:'用户资料',
    component:()=>import ('../../src/views/userInformation/index.vue'),
    meta:{
      login:true,
      title:'用户资料'
    }
  },
  {
    path:'/editRecord.html',
    name:'用户资料',
    component:()=>import ('../../src/views/editRecord/index.vue'),
    meta:{
      showTab:false,
      title:'修改记录',
      login:true
    }
  },
  {
    path:'/userEdit.html',
    name:'完善信息',
    component:()=>import ('../../src/views/userEdit/index.vue'),
    meta:{
      login:true
    }
  },
  {
    path:'/quickEdit.html',
    name:'完善信息',
    component:()=>import ('../../src/views/quickEdit/index.vue'),
    meta:{
      showTab:false,
      title:'完善信息',
      login:true,
    }
  },
  {
    path:'/switchUser.html',
    name:'切换身份',
    component:()=>import ('../../src/views/switchUser/index.vue'),
    meta:{
      showTab:false,
      title:'切换身份',
      login:true,
    }
  },
  {
    path:'/qb2Success.html',
    name:'切换身份',
    component:()=>import ('../../src/views/qb2Sucess/index.vue'),
    meta:{
      showTab:true,
      title:'切换身份',
      login:true,
    }
  },
  {
    path:'/hots.html',
    name:'热门提问',
    component:()=>import ('../../src/views/hotQuestion/index.vue'),
    meta:{
      showTab:true,
      title:'热门提问',
      login:false,
    }
  },
  {
    path:'/commentSuccess.html',
    name:'已提交',
    component:()=>import ('../../src/views/informationDoctor/components/success/index.vue'),
    meta:{
      showTab:false,
      title:'已提交',
      login:true,
    }
  },
  // 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
