/*
 * @Description: 
 * @Version: 1.0
 * @Author: caoyuheng
 * @Date: 2022-11-28 19:44:46
 * @LastEditors: caoyuheng
 * @LastEditTime: 2023-08-10 15:44:12
 * @FilePath: /doctorplat_qz_wx/src/store/index.js
 * Copyright (C) 2023 caoyuheng. All rights reserved.
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url:'https://www.admin.quanzhi360.com:446/',
    // url:"http://doctor.realid315.com/",
    type:'全直伙伴',//性取向提示类型
    pageType:'1'
  },
  getters: {
  },
  mutations: {
    SET_PAGETYPE(state,type){
      state.pageType=type
    }
  },
  actions: {
  },
  modules: {
  }
})
